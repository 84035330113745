import test from './components/test'
import slider from './components/flickity'

document.addEventListener(
  'DOMContentLoaded',
  () => {
    test()
    slider()
  },
  false
)
