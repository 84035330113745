/**
 * Settings for flickity slider in top page
 */

declare const Flickity:any


const slider = () => {
  const caseCarousel = document.querySelector('.js-flickity-top');

  if (!caseCarousel) return
  let caseFlickity = new Flickity(caseCarousel, {
    wrapAround: true,
    autoPlay: 3000,
    prevNextButtons: false,
    adaptiveHeight: true
  });
}

export default slider
